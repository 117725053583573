body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span {
  font-family: Poppins, sans-serif;
}

.modal-backdrop {
  z-index: -1 !important;
}

img {
  object-fit: cover;
}


@media screen and (max-width: 991px) {
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .custom-collapse.collapse.show {
    z-index: 3;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    display: block !important;
  }
}

@media (min-width: 529px) {
  .container--md[data-v-67cd66d8] {
    max-width: 370px;
  }
}